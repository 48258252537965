import instagram from "../assets/images/instagram.svg";
import facebook from "../assets/images/facebook.svg";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="newDonate">
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="QEPCEU8QXMHWW"
            />
            <input
              type="submit"
              value="Empower The Arts - Donate Today"
              className="donateButton"
            />
          </form>
        </div>
      </div>
      <div className="footer">
        <div className="newAddress">
          <h3>
            <a
              href="https://maps.app.goo.gl/n9KaQZjMJJgNMu2GA"
              target="_blank"
              rel="noreferrer"
              alt="Google Maps link for NewLook Performing Arts Center"
            >
              225 W Worth St, Grapevine, TX 76051
            </a>
          </h3>
        </div>
        <div className="social">
          <ul className="socialIcons">
            <li>
              <a
                href="https://www.facebook.com/NewlookPAC"
                target="_blank"
                rel="noreferrer"
                role="button"
              >
                <img src={facebook} alt="Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/newlookperformingarts/"
                target="_blank"
                rel="noreferrer"
                role="button"
              >
                <img src={instagram} alt="Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
