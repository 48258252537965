import PropTypes from "prop-types";

import newlookMap from "../assets/images/mapNewlook.png";

const ModalInfo = ({ modalSource }) => {
  switch (modalSource) {
    case "rehearsal": {
      return (
        <>
          <div className="modal-content">
            <h2>Rehearsal and Performance Schedules:</h2>
            <div>
              <h3>Aristocats Kids!</h3>
              <ul>
                <li>Auditions: 8/2-8/3</li>
                <li>Callbacks: 8/4</li>
                <li>Rehearsals: Begin 8/12</li>
                <li>
                  Rehearsal Schedule: Mon/Wed 5-6:30 Saturday 8/21 12-1:30
                </li>
                <li>Performances: 9/27-10/6, Fri/Sat 7pm, Sat/Sun 2pm</li>
              </ul>
            </div>
            <div>
              <h3>Carrie The Musical</h3>
              <ul>
                <li>Auditions: 8/22-8/24</li>
                <li>Callbacks: 8/25</li>
                <li>Rehearsals: Begin 8/26 </li>
                <li>
                  Mon/Wed 7-9 (or based on availability) Sundays 4-7 (or based
                  on availability)
                </li>
                <li>Performances: 10/18-10/27, Fri/Sat 8pm, Sun 3pm</li>
              </ul>
            </div>
            <div>
              <h3>The Addams Family: Young@Part Edition</h3>
              <ul>
                <li>Auditions: 8/22-8/24</li>
                <li>Callbacks: 8/25</li>
                <li>Rehearsals: Begin 9/10</li>
                <li>Tues/Thurs 5-7 Saturday 11/2 2-4pm</li>
                <li>
                  Performances: 11/8-11/17, Fri/Sat 7:30pm, Sat/Sun 2:30pm
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    }
    case "prepare": {
      return (
        <>
          <div className="modal-content col-2">
            <div>
              <h2>What to Prepare/What to Expect</h2>
              <p>
                For youth productions especially, you can expect a low stakes
                and fun audition!
              </p>
            </div>
            <div>
              <p>
                {`Please prepare 1 song, 30-90 seconds long, in the style of the show.
            Optionally, you can prepare a contrasting second song. Bring a
            device with backing/piano accompaniment/karaoke tracks (no vocals)
            cued up; we'll provide a speaker for connection. After your song,
            you may be asked to perform a cold reading from the script. You'll
            have time to review it, and we'll answer any questions you may have.
            Your audition will last approximately 7 minutes.`}
              </p>
              <p>
                Please arrive about 10 minutes before your scheduled audition
                time. Bring a headshot (or photo of yourself) and your resume,
                if you have one. You will be given an audition form to fill out.
                Please be prepared to list any conflicts you will have with the
                rehearsal and/or performance schedule, which is posted above and
                will also be posted at auditions.
              </p>
              <p>
                The cast list will generally go out within 5-7 days after your
                audition, usually less. To ensure your casting email does not go
                to your spam folder, please add us to your contacts:{" "}
                <a href="mailto:newlookperformingarts@gmail.com">
                  newlookperformingarts@gmail.com
                </a>
              </p>
            </div>
          </div>
        </>
      );
    }
    case "tuition": {
      return (
        <>
          <div className="modal-content col-2">
            <div>
              <h2>Tuition</h2>
            </div>
            <div>
              <p>Tuition for all students grades K-12 is $400.</p>
              <p>
                This tuition includes their costume, though they will usually be
                asked to provide their own shoes, socks, and underclothes.
              </p>
              <p>
                A link to pay will be emailed out with the cast list. You may
                pay in full or on a payment plan.
              </p>
              <p>Siblings receive a 10% discount. </p>
              <p>There are no hidden fees or ticket purchase minimums. </p>
            </div>
          </div>
        </>
      );
    }
    case "locate": {
      return (
        <>
          <div className="modal-content">
            <h2>Location</h2>
            <div>
              <p>
                All auditions, callbacks, rehearsals and performances take place
                inside The Vine at 225 W Worth St. Grapevine 76051.
              </p>
              <p>
                Our theater is in the back, on the opposite side of the big
                awning and automatic double doors.
              </p>
            </div>
            <img
              src={newlookMap}
              alt="Map displaying entrance to NewLook Performing Arts Center"
            />
          </div>
        </>
      );
    }
    case "virtual": {
      return (
        <>
          <div className="modal-content col-2">
            <div>
              <h2>Virtual Auditions</h2>
            </div>
            <div>
              <p>
                Though we strongly prefer to see you audition in person- we
                absolutely understand that sometimes auditions fall on a weekend
                where you are out of town or otherwise unable to be there in
                person.
              </p>
              <p>
                If this happens, please email your audition (1-2 songs in the
                style of the show, and a short monologue), your headshot, and
                resume (if you have one) to:{" "}
                <a href="mailto:newlookperformingarts@gmail.com">
                  newlookperformingarts@gmail.com
                </a>{" "}
                with the subject line: Virtual Audition: _yourname_,
                _showyouareauditioningfor_.
              </p>
              <p>
                We will then send you an audition form to fill out and return to
                us as promptly as you can.{" "}
              </p>
            </div>
          </div>
        </>
      );
    }
    default: {
      return;
    }
  }
};

ModalInfo.propTypes = {
  modalSource: PropTypes.string.isRequired,
};

export default ModalInfo;
