import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { gsap } from "gsap";

import logo from "./assets/images/NewlookFullLogo.svg";
import "./App.css";
import About from "./About";
import Camps from "./Camps";
import BoxOffice from "./BoxOffice";
import Auditions from "./Auditions";
import News from "./News";

function App() {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.fromTo("#logo", { opacity: 0, y: -20 }, { opacity: 1, y: 0 })
      .fromTo(".light", { opacity: 0 }, { opacity: 1 })
      .fromTo(
        ".light > span",
        { opacity: 0, x: -10 },
        { duration: 0.5, opacity: 1, x: 0, stagger: 0.5 }
      );
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const location = useLocation();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Link to="/" onClick={location.pathname === "/" ? toggleNav : null}>
          <img src={logo} alt="Newlook Performance Arts Center" id="logo" />
        </Link>
      </header>

      <section className="Main-section">
        <nav
          className={`Main-nav quarter-circle-nav ${isNavOpen ? "open" : ""}`}
        >
          <button className="nav-toggle" onClick={toggleNav}>
            ☰
          </button>
          <div className="nav-links">
            <div className="main-links">
              <ul>
                <li>
                  <Link to="/about" onClick={toggleNav}>
                    <div className="light lpeach">
                      <span>About</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/camps" onClick={toggleNav}>
                    <div className="light lpeach">
                      <span>Classes & Camps</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/box-office" onClick={toggleNav}>
                    <div className="light lpeach">
                      <span>Box Office</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/auditions" onClick={toggleNav}>
                    <div className="light lpeach">
                      <span>Upcoming Auditions</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://view.flodesk.com/emails/66a9a07fea782ed6fbe84e8c"
                    onClick={toggleNav}
                  >
                    <div className="light lpeach">
                      <span>Newsletter</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="mailto:Newlookperformingarts@gmail.com"
                    onClick={toggleNav}
                  >
                    <div className="light lpeach">
                      <span>Contact Us</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="news-links">
              <div className="newsItems"></div>
            </div>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<News />} />
          <Route path="/about" element={<About />} />
          <Route path="/camps" element={<Camps />} />
          <Route path="/box-office" element={<BoxOffice />} />
          <Route path="/auditions" element={<Auditions />} />
        </Routes>
      </section>
    </div>
  );
}

const Root = () => (
  <Router>
    <App />
  </Router>
);

export default Root;
