import PropTypes from "prop-types";

const Production = ({ name, img, descrip, link, url }) => {
  return (
    <div className="production">
      <h2>{name}</h2>
      {img && <img src={img} alt={name} />}
      <p>{descrip}</p>
      {url && (
        <a href={url} target="_blank" rel="noreferrer" role="button">
          {link}
        </a>
      )}
    </div>
  );
};

Production.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  descrip: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Production;
