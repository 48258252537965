import InfoSide from "./components/InfoSide";
import Addams from "./assets/images/addams.png";
import Footer from "./components/Footer";

const News = () => {
  return (
    <section className="news">
      <InfoSide
        side="left"
        img={Addams}
        alt="Addams Family"
        msg="New look : Same commitment to excellence."
      />
      <div className="homeLinks">
        <div className="homeLink">
          <a href="./camps" role="button">
            Now Enrolling Fall 2024 Classes
          </a>
        </div>
        <div className="homeLink">
          <a href="./Auditions" role="button">
            Audition For Our Fall 2024 Lineup
          </a>
        </div>
      </div>
      <div className="divider dividerNews"></div>
      <Footer />
    </section>
  );
};

export default News;
