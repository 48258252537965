/* eslint-disable react/no-unescaped-entities */

import Footer from "./components/Footer";

const About = () => {
  return (
    // create an exaggerated title for the page with a background of a light blue and white text

    <section className="about">
      <div className="about-title">
        <h1 className="exaggerated-title">About</h1>
      </div>
      <h2>
        <span>Mission:</span>
      </h2>
      <p>
        At Newlook Performing Arts, our mission is to bring together diverse
        individuals and communities, offering enriching artistic experiences
        that cater to all ages in an inclusive, accessible environment. Our
        nonprofit theater aims to ignite passion for the performing arts,
        nurturing personal and artistic growth.
      </p>

      <div className="divider dividerAbout"></div>

      <h2>
        <span>About OhlookPAC:</span>
      </h2>
      <p>
        Ohlook Performing Arts Center debuted in 2002 with just four students in
        its first class (two of whom were family members!). Founded by
        professional singers Matthew Lord and Jill Blalock Lord, the center
        quickly gained a reputation as a welcoming space where children could
        freely express themselves. This tiny theater school emphasized
        opportunity over competition, fostering a positive and caring
        environment.
      </p>
      <p>
        Over the years, Ohlook grew significantly through word of mouth. Each
        year, hundreds of children, from age 3 and up, participated in our
        classes. In its first six years alone, Ohlook produced an impressive 52
        musicals and straight plays. While most schools typically produced only
        one musical and one or two plays annually, Ohlook provided young actors
        with numerous opportunities to study and gain experience.
      </p>
      <p>
        Each semester, Ohlook offered classes and productions designed to help
        students grow, not just as performers but as individuals. Our top
        priority was to increase confidence and self-esteem among our students.
        We created a safe environment where students could explore, fail, and
        succeed without fear. At Ohlook, we rewarded effort, not just natural
        talent, believing that nurturing hard work allowed true talent to shine.
      </p>
      <p>
        Ohlook also took pride in bringing in professionals to work with our
        students, providing them with unique insights into the theater industry.
        We were the only children’s theater in the metroplex to host so many
        high-caliber professionals. Over the years, we welcomed Anthony Rapp
        (original cast of “Rent” on Broadway), Jay Hunter Morris (international
        opera star), Keith Buterbaugh (from Broadway’s “Company,” “Phantom,” and
        “Sweeney Todd”), Candace Evans (international
        director/choreographer/actor), Thomas Douglas (conductor and coach at
        Carnegie Mellon University), Marla Mindelle (“The Drowsy Chaperone”,
        “Titanique”), Tony Award winner Lindsay Mendez (“Carousel,” “Wicked,”
        “Merrily We Roll Along”), and Corey Cott (Broadway’s “Bandstand,”
        “Newsies”), among several others.
      </p>
      <p>
        Theater was like a family at Ohlook, and our older students and alums
        even named us "Ohlookians."
      </p>

      <div className="divider dividerAbout"></div>

      <h2>
        <span>About NewlookPAC:</span>
      </h2>
      <p>
        In 2020, a tragic worldwide pandemic struck, dealing a severe blow to
        the theater arts. We knew we would eventually come back strong, but in
        the meantime, the original founders of Ohlook pursued new ways to create
        and grow in different endeavors. When the pandemic subsided and it was
        safer to perform in person, they found themselves with two grown
        children and successful new businesses. Ohlook took a hiatus.
      </p>
      <p>
        In 2024, a small group of both old and new Ohlookians decided to renew
        the original spirit of Ohlook, and we dubbed the theater "Newlook" with
        a respectful nod to our roots. We are committed to continuing to bring
        exemplary theatrical education to any and all students who wish to
        learn, and to provide our students and the community with productions of
        the highest standard.
      </p>
      <p>
        As we transition to new leadership, we cherish the legacy that brought
        us here and hope you'll continue to be a part of our family. Welcome to
        Newlook Performing Arts Center!
      </p>

      <div className="divider dividerAbout"></div>

      <h2>
        <span>Board of Directors:</span>
      </h2>
      <h3>Coming Soon!</h3>
      <Footer />
    </section>
  );
};

export default About;
