import React, { useState } from "react";

import Footer from "./components/Footer";
import addamsMusical from "./assets/images/addams.png";
import aristocatsMusical from "./assets/images/aristocats.png";
import carrieMusical from "./assets/images/carrie.png";

import Production from "./components/Production";
import ModalInfo from "./components/ModalInfo";

const Auditions = () => {
  const [currentProd, setCurrentProd] = useState({
    name: "Select one of the following productions to learn more:",
    img: "",
    descrip: "",
    link: "",
    url: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const [modalSource, setModalSource] = useState("");

  const toggleModal = (source) => {
    setOpenModal(!openModal);
    setModalSource(source);
  };

  // Productions

  const addams = {
    name: "The Addams Family young@part Edition",
    img: addamsMusical,
    descrip:
      "The Addams Family is a musical comedy with music and lyrics by Andrew Lippa and a book by Marshall Brickman and Rick Elice. The show is based upon The Addams Family characters created by Charles Addams in his single-panel gag cartoons, which depict a ghoulish American family with an affinity for all things macabre.",
    url: "https://signup.com/go/vsGuJst",
  };

  const aristocats = {
    name: "Aristocats The Musical",
    img: aristocatsMusical,
    descrip:
      "Based on the beloved Disney animated film, and featuring a jazzy, upbeat score, Disney’s The Aristocats KIDS is a non-stop thrill ride of feline fun, complete with unbelievable twists and turns.In the heart of Paris, a kind and eccentric millionairess wills her entire estate to Duchess, her high-society cat, and her three little kittens. Laughs and adventure ensue as the greedy, bumbling butler pulls off the ultimate catnap caper. Now it's up to the rough-and-tumble alley cat, Thomas O'Malley, and his band of swingin' jazz cats to save the day.",
    url: "https://signup.com/go/sGuAPGK",
  };

  const carrie = {
    name: "Carrie the Musical",
    img: carrieMusical,
    descrip:
      "Carrie White is a teenage outcast who longs to fit in. At school, she’s bullied by the popular crowd, and virtually invisible to everyone else. At home, she’s dominated by her loving but cruelly controlling mother. What none of them know is that Carrie’s just discovered she’s got a special power, and if pushed too far, she’s not afraid to use it.",
    url: "https://signup.com/go/vsGuJst",
  };

  const handleProd = (prod) => setCurrentProd(prod);

  return (
    <section className="auditions">
      <div className="audition-title">
        <h1 className="exaggerated-title">Auditions</h1>
      </div>
      <div className="auditions">
        <div className="productions">
          <div className="production-list">
            <h2>We are thrilled to announce our Fall 2024 lineup:</h2>
            {/* <div className="production-item">
              <h2>Youth Production: Ages 3-11</h2>
              <button
                className="production-link"
                onClick={() => handleProd(aristocats)}
                aria-label={`Select ${aristocats.name}`}
              >
                {aristocats.name}
              </button>
            </div> */}
            <div className="production-item">
              <h2>Jr Production: Ages 11-15</h2>
              <button
                className="production-link"
                onClick={() => handleProd(addams)}
                aria-label={`Select ${addams.name}`}
              >
                {addams.name}
              </button>
            </div>
            <div className="production-item">
              <h2>Elite Production: Ages 14-Adult</h2>
              <button
                className="production-link"
                onClick={() => handleProd(carrie)}
                aria-label={`Select ${carrie.name}`}
              >
                {carrie.name}
              </button>
            </div>
            <div className="production-info">
              <h2>Additional Info:</h2>
              <button onClick={() => toggleModal("rehearsal")}>
                Rehearsal & Performance Schedules
              </button>
            </div>
            <div className="production-info">
              <button onClick={() => toggleModal("prepare")}>
                What To Prepare/What To Expect
              </button>
            </div>
            <div className="production-info">
              <button onClick={() => toggleModal("tuition")}>Tuition</button>
            </div>
            <div className="production-info">
              <button onClick={() => toggleModal("locate")}>Location</button>
            </div>
            <div className="production-info">
              <button onClick={() => toggleModal("virtual")}>
                Virtual Auditions
              </button>
            </div>
          </div>
          <div className="production-info">
            <Production
              name={currentProd.name}
              age={currentProd.age}
              img={currentProd.img}
              descrip={currentProd.descrip}
              link={`Sign up for ${currentProd.name}`}
              url={currentProd.url}
            />
          </div>
        </div>
      </div>
      {openModal && (
        <div className="modal">
          <div
            className="close-msg"
            onClick={() => {
              toggleModal();
            }}
          >
            Click here to close
          </div>
          <ModalInfo modalSource={modalSource} />
          <div className="flex flex-col items-center modal-msg"></div>
        </div>
      )}
      <div className="divider dividerAuditions"></div>
      <Footer />
    </section>
  );
};

export default Auditions;
