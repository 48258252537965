import PropTypes from "prop-types";

import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

const InfoSide = ({ side, img, alt, msg }) => {
  let title = useRef(null);

  useEffect(() => {
    const children = title.current.children;
    gsap.fromTo(
      children,
      { autoAlpha: 0, y: -20 },
      { autoAlpha: 1, y: 0, stagger: 0.1, ease: "power3.out", duration: 2 }
    );
  }, []);

  return (
    <div className="info-side">
      <img src={img} alt={alt} />
      <h1 ref={title}>
        {Array.from(msg).map((char, i) => (
          <span className="homeHeader" key={i}>
            {char}
          </span>
        ))}
      </h1>
    </div>
  );
};

InfoSide.propTypes = {
  side: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
};
export default InfoSide;
