import Footer from "./components/Footer";
import batBoy from "./assets/images/batBoy.png";

const BoxOffice = () => {
  return (
    <section className="boxOffice">
      <div className="office-title">
        <h1 className="exaggerated-title">Box Office</h1>
      </div>
      <div className="center">
        <h2>Check back soon for tickets to our Fall productions!</h2>
      </div>
      {/* <div className="shows">
        <div className="batBoy">
          <div className="showtimes">
            <img src={batBoy} alt="Bat boy from original Weekly World News" />
            <h2>Showtimes:</h2>
            <ul>
              <li>
                {" "}
                <a
                  className="showLink"
                  href="https://buy.ticketstothecity.com/purchase.php?date_id=56190"
                >
                  Friday, July 12 9pm
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="showLink"
                  href="https://buy.ticketstothecity.com/purchase.php?date_id=56191"
                >
                  Saturday, July 13 9pm
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="showLink"
                  href="https://buy.ticketstothecity.com/purchase.php?date_id=56194"
                >
                  Sunday, July 14 6pm
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="showLink"
                  href="https://buy.ticketstothecity.com/purchase.php?date_id=56192"
                >
                  Friday, July 19 9pm
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="showLink"
                  href="https://buy.ticketstothecity.com/purchase.php?date_id=56193"
                >
                  Saturday, July 20 9pm
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="showLink"
                  href="https://buy.ticketstothecity.com/purchase.php?date_id=56195"
                >
                  Sunday, July 21 6pm
                </a>
              </li>
            </ul>
          </div>

          <div className="showInfo">
            <h1>BAT BOY FOUND AT NEWLOOK!</h1>
            <div>
              <h2>Bat Boy: The Musical</h2>
              <ul className="info-background">
                <li></li>
                <li className="credits">
                  Story and book by Keythe Farley and Brian Flemming
                </li>
                <li className="credits">
                  Music and lyrics by Laurence O&apos;Keefe
                </li>
              </ul>
              <p className="no-pad">
                THE STORY: Based on a story in The Weekly World News, BAT BOY:
                THE MUSICAL is a musical comedy/horror show about a half
                boy/half bat creature who is discovered in a cave near Hope
                Falls, West Virginia. For lack of a better solution, the local
                sheriff brings Bat Boy to the home of the town veterinarian, Dr.
                Parker, where he is eventually accepted as a member of the
                family and taught to act like a “normal” boy by the
                veterinarian’s wife, Meredith, and teenage daughter, Shelley.
                Bat Boy is happy with his new life, but when he naively tries to
                fit in with the narrow-minded people of Hope Falls, they turn on
                him, prodded by the machinations of Dr. Parker, who secretly
                despises Bat Boy. Shelley and Bat Boy, who have fallen in love,
                run away together from the ignorant townfolk and have a blissful
                coupling in the woods, but their happiness is shattered when
                Meredith arrives and reveals a secret. Soon the entire town
                arrives and hears the shocking story of Bat Boy’s unholy origin.
              </p>
              <p>
                CONTENT WARNING: This show contains content not suitable for
                children. Batboy is rated PG-13 for violence, mild drug use,
                sexual content, and language
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="divider dividerBoxOffice"></div> */}
      <Footer />
    </section>
  );
};

export default BoxOffice;
