import React, { useState } from "react";

import Production from "./components/Production";
import Footer from "./components/Footer";

const Camps = () => {
  const [currentProd, setCurrentProd] = useState({
    name: "",
    descrip: "",
    age: "",
    img: "",
    link: "",
  });

  const [visibleGroups, setVisibleGroups] = useState({
    ages3to5: false,
    ages5to8: false,
    ages6to8: false,
    ages7to13: false,
    ages8to12: false,
    ages9to13: false,
    ages10to15: false,
    ages12plus: false,
    ages13to18: false,
    tripleThreat: false,
  });

  // Classes

  const jam = {
    name: "Musical Jam!",
    descrip:
      "Skip, leap, jump, sing, and play instruments! Experience the joy of rhythm, tempo, and dynamics in this exciting musical theater class for ages 3-5.\n\nThrough playful and engaging activities, children will explore the fundamentals of music, including learning basic percussion instruments and enjoying group jams. They'll stretch their imaginations and develop their fine and gross motor skills through movement and dance exercises.\n\nAs they build confidence in singing, acting, and dancing, they will prepare for two delightful performances: a holiday showcase in December and a spring performance in May, where they'll share their growing talents and enthusiasm with family and friends.",
    age: "",
    img: "",
    link: "Sign up for Musical Jam!",
  };

  const moves = {
    name: "Moves & Grooves: Pre-K Dance",
    descrip:
      "Get ready to dance and have fun with “Moves & Grooves: Pre-K Dance”! This lively class is designed for 3-5 year olds and introduces the basics of tap, jazz, and ballet in a playful and engaging way.\n\nOur little dancers will explore rhythm, coordination, and movement while enjoying creative activities and games.\n\nThroughout the year, they’ll develop foundational dance skills, build confidence, and make new friends.\n\nTo showcase their progress, students will have the opportunity to shine in two exciting performances—one in December and another in May.\n\nJoin us for a fun-filled journey where your child will dance, laugh, and grow!",
    age: "",
    img: "",
    link: "Sign up for Moves & Grooves: Pre-K Dance",
  };

  const adventure = {
    name: "Adventures In Acting",
    descrip:
      "Welcome to Adventures in Acting, an exciting and dynamic acting class designed for children aged 5-8. This class offers a fun and supportive environment where young actors can explore their creativity, build confidence, and develop essential skills through the magic of storytelling.\n\nThroughout the year, our talented instructor will guide the children as they act out beloved and popular stories, bringing characters to life with their unique interpretations. Each session is filled with imaginative play, dramatic exercises, and teamwork, allowing kids to immerse themselves in the world of theater.\n\nAdventures in Acting culminates in two delightful performances for family and friends. In December and May, our young performers will take the stage to showcase their hard work and talent in exciting performances.\n\nJoin us for an unforgettable Adventure in Acting, where every child can shine and create special memories!",
    age: "",
    img: "",
    link: "Sign up for Adventures In Acting",
  };

  const broadway = {
    name: "Broadway Kids",
    descrip:
      "This class combines imaginative play, singing, acting, and movement to introduce and establish the building blocks of musical theater, fostering creativity and nurturing self-esteem.\n\nThrough engaging songs, musical theater games, and enchanting stories, these budding performers learn in a playful and creative environment. They develop confidence in singing and acting, strengthen their skills, and have fun!\n\nAs part of their journey, the class culminates in two delightful performances: one in December and one in May, where they will have the chance to share their growing talents and enthusiasm with family and friends.",
    age: "",
    img: "",
    link: "Sign up for Broadway Kids",
  };

  const storytell = {
    name: "Scenes & Storytelling",
    descrip:
      "Our Scenes & Storytelling class provides children aged 8-12 with an engaging and comprehensive exploration of acting fundamentals.\n\nHeld every Monday from 4:30 PM to 5:30 PM, this course delves into improvisation, script analysis, and monologue performance.\n\nStudents will develop their skills in character building, emotional expression, and understanding character objectives.\n\nEmphasizing collaboration and creativity, young actors will work together to invent characters and write an original script.\n\nThe class culminates in two exciting performances, one in December and another in May, showcasing their talents and progress.\n\nJoin us to embark on a dynamic journey into the world of acting and storytelling!",
    age: "",
    img: "",
    link: "Sign up for Scenes & Storytelling",
  };

  const improv = {
    name: "Improv Showdown",
    descrip:
      "Unleash your creativity in our Improv Class! This exciting and dynamic course helps young actors develop quick thinking, spontaneity, and teamwork through a variety of fun improv games and exercises.\n\nParticipants will learn the fundamentals of improvisation, including scene creation, character development, and collaboration, all in a supportive and energetic environment.\n\nJoin us to boost your confidence, enhance your acting skills, and have a blast making new friends while performing on the spot!\n\nThere are two performances for this class, one in December and one in May.",
    age: "",
    img: "",
    link: "Sign up for Improv Showdown",
  };

  const combat = {
    name: "Stage Combat For Kids",
    descrip:
      "Step into the exciting world of stage combat in our class designed specifically for children!\n\nThis action-packed course teaches young performers the fundamentals of safe and realistic stage fighting, including techniques for punches, kicks, falls, and more.\n\nUnder the guidance of an experienced instructor, students will learn the importance of safety, control, and cooperation while choreographing thrilling fight scenes.\n\nPerfect for building confidence and physical coordination, this class ensures a fun and engaging experience for all participants.",
    age: "",
    img: "",
    link: "Sign up for Stage Combat For Kids",
  };

  const studies = {
    name: "Scene Studies",
    descrip:
      "This course offers an immersive journey into advanced acting through intensive scene work and detailed script analysis.\n\nStudents will delve deep into character development, exploring motivation and performance with a strong focus on interpreting and translating dramatic scripts.\n\nThroughout the year, they will write and perform their own monologues, guided by the instructor, and tackle scenes from prewritten plays, culminating in two performances, one in December and one in May.\n\nThis dual approach of creative writing and traditional script work aims to sharpen their craft and deepen their understanding of theatrical storytelling.",
    age: "",
    img: "",
    link: "Sign up for Scene Studies",
  };

  const triple = {
    name: "Triple Threat",
    descrip:
      "Our Triple Threat Company at Newlook Performing Arts is designed for dedicated performers eager to excel in acting, singing, and dancing. This elite program, our highest level of training, demands commitment and passion from its students.\n\nTo join this class, a virtual audition is required, ensuring that each member is ready to immerse themselves in the rigorous and rewarding world of theater. As part of the Triple Threat Company, you’ll have the opportunity to compete in local competitions and participate in exciting performance opportunities.\n\nAdditionally, students will gain exclusive access to masterclasses with industry professionals, offering invaluable insights and guidance from those who have made their mark in the theater world. Join us and be part of a supportive community where passion meets excellence, refine your skills and realize your potential as a true triple threat performer.\n\n**Our Triple Threat Company students receive a discount on all productions they participate in. For audition information please email us at newlookperformingarts@gmail.com with the subject: Triple Threat Company Audition, and join us on August 16th at 6pm for a Parent Information Meeting!",
    age: "",
    img: "",
    link: "Sign up for Triple Threat",
  };

  const actingHomies = {
    name: "Acting with my Homies",
    descrip:
      "'Acting with My Homies' is a dynamic homeschool course designed for students ages 7-13 to explore the world of acting and stage performance.\n\nThrough interactive lessons, students will learn fundamental acting techniques, stage presence, character development, and teamwork.\n\nThe course culminates in two exciting performances—one in December and another in May—where students will showcase their talents in front of an audience.\n\nThis course not only nurtures creativity but also builds confidence and collaboration skills in a fun, supportive environment.",
    age: "",
    img: "",
    link: "Sign up for Acting with my Homies",
  };

  const singingHomies = {
    name: "Singing with my Homies",
    descrip:
      "'Singing with My Homies' is a fun homeschool course where students aged 7-13 will explore the world of singing and stage performance!\n\nThey'll learn vocal techniques, how to keep their voices healthy, and music theory basics.\n\nWith plenty of group practice and guidance, they'll get ready to shine in two exciting performances—one in December and one in May.\n\nJoin us for a tuneful adventure where singing with friends takes center stage!",
    age: "",
    img: "",
    link: "Sign up for Singing with my Homies",
  };

  const disneySpot = {
    name: "Spotlight on Disney!",
    descrip:
      "'Spotlight on Disney!' is an exciting course in our Spotlight Showcase series, perfect for students ages 7-13 who love Disney and performing on stage.\n\nGuided by the fabulous Jason, students will learn essential acting techniques, stage presence, and character development.\n\nTogether, they'll collaborate to create a magical showcase that brings to life their favorite Disney characters from beloved movies.\n\nThe course will culminate in a December performance, where students will share their Disney-inspired creations with an audience, blending creativity, teamwork, and the enchanting world of Disney.",
    age: "",
    img: "",
    link: "Sign up for Singing with my Homies",
  };

  const danceCondition = {
    name: "Dance Conditioning",
    descrip:
      "'Dance Conditioning' is the perfect course for intermediate and advanced dancers looking to refine their skills for the stage.\n\nUnder the expert guidance of LaQuan, you'll focus on strengthening and toning your technique to elevate your dance performance.\n\nThis class is designed to push your limits and help you become the best dancer you can be.\n\nGet ready to enhance your skills, build strength, and take your dancing to the next level!",
    age: "",
    img: "",
    link: "Sign up for Dance Conditioning",
  };

  const centerStage = {
    name: "Center Stage Teens",
    descrip:
      "'Center Stage Teens' is your ticket to mastering advanced acting!\n\nIn this exciting class, you’ll dive into character development, scene work, and script analysis.\n\nYou’ll get to write and perform your own monologues, as well as tackle scenes from prewritten plays.\n\nWith guidance from your instructor, you'll bring characters to life and prepare for two awesome performances—one in December and one in May.\n\nThis class is all about sharpening your acting skills and discovering the magic of theatrical storytelling!",
    age: "",
    img: "",
    link: "Sign up for Dance Conditioning",
  };

  const toggleVisibility = (group) => {
    setVisibleGroups((prevState) => ({
      ...prevState,
      [group]: !prevState[group],
    }));
  };

  const handleProd = (prod) => setCurrentProd(prod);

  const renderDescription = (description) => {
    return description
      .split("\n\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  return (
    <section className="camps">
      <div className="camp-title">
        <h1 className="exaggerated-title">Classes & Camps</h1>
      </div>
      <div className="camp">
        <div>
          <div className="camplist">
            <div className="classtitle">
              <h2>Select an age group and class to learn more:</h2>
            </div>
            <div className="production-list classage1">
              <h2
                className="light"
                onClick={() => toggleVisibility("ages3to5")}
              >
                Ages 3-5
              </h2>
              {visibleGroups.ages3to5 && (
                <div className="production-item">
                  <button
                    className="production-link"
                    onClick={() => handleProd(jam)}
                    aria-label={`Select ${jam.name}`}
                  >
                    {jam.name}
                  </button>
                  <button
                    className="production-link"
                    onClick={() => handleProd(moves)}
                    aria-label={`Select ${moves.name}`}
                  >
                    {moves.name}
                  </button>
                </div>
              )}

              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages5to8")}
                >
                  Ages 5-8
                </h2>
                {visibleGroups.ages5to8 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(adventure)}
                      aria-label={`Select ${adventure.name}`}
                    >
                      {adventure.name}
                    </button>
                  </div>
                )}
              </div>
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages6to8")}
                >
                  Ages 6-8
                </h2>
                {visibleGroups.ages6to8 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(broadway)}
                      aria-label={`Select ${broadway.name}`}
                    >
                      {broadway.name}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="production-list classage2">
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages7to13")}
                >
                  Ages 7-13
                </h2>
                {visibleGroups.ages7to13 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(actingHomies)}
                      aria-label={`Select ${actingHomies.name}`}
                    >
                      {actingHomies.name}
                    </button>
                    <button
                      className="production-link"
                      onClick={() => handleProd(singingHomies)}
                      aria-label={`Select ${singingHomies.name}`}
                    >
                      {singingHomies.name}
                    </button>
                    <button
                      className="production-link"
                      onClick={() => handleProd(disneySpot)}
                      aria-label={`Select ${disneySpot.name}`}
                    >
                      {disneySpot.name}
                    </button>
                  </div>
                )}
              </div>
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages8to12")}
                >
                  Ages 8-12
                </h2>
                {visibleGroups.ages8to12 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(storytell)}
                      aria-label={`Select ${storytell.name}`}
                    >
                      {storytell.name}
                    </button>
                  </div>
                )}
              </div>
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages9to13")}
                >
                  Ages 9-13
                </h2>
                {visibleGroups.ages9to13 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(improv)}
                      aria-label={`Select ${improv.name}`}
                    >
                      {improv.name}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="production-list classage3">
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages10to15")}
                >
                  Ages 10-15
                </h2>
                {visibleGroups.ages10to15 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(combat)}
                      aria-label={`Select ${combat.name}`}
                    >
                      {combat.name}
                    </button>
                  </div>
                )}
              </div>
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages12plus")}
                >
                  Ages 12+
                </h2>
                {visibleGroups.ages12plus && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(danceCondition)}
                      aria-label={`Select ${danceCondition.name}`}
                    >
                      {danceCondition.name}
                    </button>
                  </div>
                )}
              </div>
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("ages13to18")}
                >
                  Ages 13-18
                </h2>
                {visibleGroups.ages13to18 && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(studies)}
                      aria-label={`Select ${studies.name}`}
                    >
                      {studies.name}
                    </button>
                    <button
                      className="production-link"
                      onClick={() => handleProd(centerStage)}
                      aria-label={`Select ${centerStage.name}`}
                    >
                      {centerStage.name}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="production-list classage4">
              <div className="production-item">
                <h2
                  className="light"
                  onClick={() => toggleVisibility("tripleThreat")}
                >
                  Triple Threat (8-18)
                </h2>
                {visibleGroups.tripleThreat && (
                  <div className="production-item">
                    <button
                      className="production-link"
                      onClick={() => handleProd(triple)}
                      aria-label={`Select ${triple.name}`}
                    >
                      {triple.name}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="production-info classinfo">
              <Production
                name={currentProd.name}
                age={currentProd.age}
                descrip={renderDescription(currentProd.descrip)}
                link={currentProd.link}
                url="https://classbug.com/businesses/3181/offerings#monthly_tuition_offerings_progressive_load"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="divider dividerCamps"></div>
      <Footer />
    </section>
  );
};

export default Camps;
